import React from 'react';
import Form from '../Form/Form.js';

import './evento.css';


export default class Evento extends React.Component{
    constructor(props){
        super(props);
        this.state=this.props.Datos;
        this.state.ventanaok=0;
    }

    render(){
        let respuesta;
        let formulario;
        console.log(this.props.Datos);
        formulario=
        {
            titulo:'Login',
            size:'s12',
            clase:'SolicitudContraseña',
            accion:this.setData,
            boton:[
                {
                    type:"submit",
                    name:"sumbit",
                    label:"Registrar",
                    clase:"LoginBotonReg"
                }
            ],
            campos:[
                {
                    type:"text",
                    name:"Nombre",
                    label:"Nombre",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"email",
                    name:"email",
                    label:"Correo Electrónico",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Empresa",
                    label:"Empresa o Institución",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Cargo",
                    label:"Cargo",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"NIT",
                    label:"NIT de empresa",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                }
            ]
        }
        respuesta=this.plaseRegister(<Form param={formulario} />);
        if(this.state.ventanaok===1)
            respuesta=this.plaseok();
        return respuesta;
    }

    setData = (data, e) => {
        console.log('datos');
        let url = 'http://citashagamosnegocio.aeinformatica.net/backbone/Process.php';
        var formData = new FormData();
        formData.append('accion', 3);
        let param = { method: 'POST', headers: {}, body: formData };

        formData.append('Nombre', data.Nombre);
        formData.append('email', data.email);
        formData.append('Empresa', data.Empresa);
        formData.append('Cargo', data.Cargo);
        formData.append('NIT', data.NIT);
       
        const obtener_datos = async () => {
            const arrayDatos = await fetch(url, param).then(res => res.json());
            if (arrayDatos.respuesta === 'ok') {       //Operacion exitosa
                console.log('operacion exitosa');
                console.log(arrayDatos);
                this.setState({ventanaok:1});
            }
            else {
                this.setState({ error: 'Error en en el servidor' });
                console.log(arrayDatos.parametros);
            }
        }
        obtener_datos();
    }
    plaseRegister(form){
        return (
            <div className="row">
                <div className="col s12 m8 offset-m2 l6 offset-l3 Login LoginBorde bgsecondary">
                    <div className="row">
                        <div className="col s12 LoginText">
                            <h4>Formulario de Registro</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 LoginBorde white">
                            <div className="row">
                                {form}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 offset-s3">
                            <div className="LoginDivisor LoginBorde"></div>
                            <img src="./IMG/AEInformatica/AE-Blanco.png" className="responsive-img" alt="" />        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    plaseok(){
        return (
            <div className="row">
                <div className="col s12 m8 offset-m2 l6 offset-l3 Login LoginBorde bgsecondary">
                    <div className="row">
                        <div className="col s12 LoginText">
                            <h4>Gracias por su registro</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 LoginBorde white">
                               <h4 className="msgOK">Estaremos contactandolo por correo electróncio</h4> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 offset-s3">
                            <div className="LoginDivisor LoginBorde"></div>
                            <img src="./IMG/AEInformatica/AE-Blanco.png" className="responsive-img" alt="" />        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /************/
}