import React from 'react';
import Form from '../Form/Form.js';

import './RegistroEmpresa.css';


export default class Panel extends React.Component{
    constructor(props){
        super(props);
        this.state=this.props.Datos;
        this.state.ventanaok=0;
    }

    render(){
        let respuesta;
        let formulario;
        console.log(this.props.Datos);
        formulario=
        {
            titulo:'Login',
            size:'s12',
            clase:'SolicitudContraseña',
            accion:this.setData,
            boton:[
                {
                    type:"submit",
                    name:"sumbit",
                    label:"Registrar",
                    clase:"LoginBotonReg"
                }
            ],
            campos:[
                {
                    type:"StaticSelect",
                    name:"DenominacionSocial",
                    label:"Denominación social",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                    options:[
                        {label:'Empresa',value:'0'},
                        {label:'Persona Natural',value:'1'},
                    ]
                },
                {
                    type:"text",
                    name:"NIT",
                    label:"Numero de NIT",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"NombreEmpresa",
                    label:"Nombre de Empresa",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Telefono",
                    label:"Telefóno",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"email",
                    label:"Correo Electrónico",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Contribuyente",
                    label:"Nombre de Contribuyente",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"NRC",
                    label:"Numero de registro (NRC)",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Direccion",
                    label:"Dirección casa matriz",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Descripcion",
                    label:"Descripción de la empresa",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Producto1",
                    label:"Producto a presentar",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"text",
                    name:"Producto2",
                    label:"Producto a presentar",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                },
                {
                    type:"StaticSelect",
                    name:"Socio",
                    label:"Es socio de GS1",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                    options:[
                        {label:'No',value:'0'},
                        {label:'Si',value:'1'},
                    ]
                },
                {
                    type:"StaticSelect",
                    name:"CodigoBarras",
                    label:"Tiene Código de Barras GS1",
                    clase:"LoginCampo",
                    size:"s10 offset-s1",
                    options:[
                        {label:'No',value:'0'},
                        {label:'Si',value:'1'},
                    ]
                },
                
            ]
        }
        respuesta=this.plaseRegister(<Form param={formulario} />);
        if(this.state.ventanaok===1)
            respuesta=this.plaseok();
        return respuesta;
    }

    setData = (data, e) => {
        let url = 'http://citashagamosnegocio.aeinformatica.net/backbone/Process.php';
        var formData = new FormData();
        formData.append('accion', 2);
        let param = { method: 'POST', headers: {}, body: formData };

        formData.append('DenominacionSocial', data.DenominacionSocial);
        formData.append('NIT', data.NIT);
        formData.append('NombreEmpresa', data.NombreEmpresa);
        formData.append('Telefono', data.Telefono);
        formData.append('email', data.email);
        formData.append('Contribuyente', data.Contribuyente);
        formData.append('NRC', data.NRC);
        formData.append('Direccion', data.Direccion);
        formData.append('Descripcion', data.Descripcion);
        formData.append('Producto1', data.Producto1);
        formData.append('Producto2', data.Producto2);
        formData.append('Socio', data.Socio);
        formData.append('CodigoBarras', data.CodigoBarras);
       
        const obtener_datos = async () => {
            const arrayDatos = await fetch(url, param).then(res => res.json());
            if (arrayDatos.respuesta === 'ok') {       //Operacion exitosa
                console.log('operacion exitosa');
                console.log(arrayDatos);
                this.setState({ventanaok:1});
            }
            else {
                this.setState({ error: 'Error en en el servidor' });
                //console.log(arrayDatos.parametros);
            }
        }
        obtener_datos();
    }
    plaseRegister(form){
        return (
            <div className="row">
                <div className="col s12 m8 offset-m2 l6 offset-l3 Login LoginBorde bgsecondary">
                    <div className="row">
                        <div className="col s12 LoginText">
                            <h4>Formulario de Registro</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 LoginBorde white">
                            <div className="row">
                                {form}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 offset-s3">
                            <div className="LoginDivisor LoginBorde"></div>
                            <img src="./IMG/AEInformatica/AE-Blanco.png" className="responsive-img" alt="" />        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    plaseok(){
        return (
            <div className="row">
                <div className="col s12 m8 offset-m2 l6 offset-l3 Login LoginBorde bgsecondary">
                    <div className="row">
                        <div className="col s12 LoginText">
                            <h4>Gracias por su registro</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 LoginBorde white">
                               <h4 className="msgOK">Estaremos contactandolo por correo electróncio</h4> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 offset-s3">
                            <div className="LoginDivisor LoginBorde"></div>
                            <img src="./IMG/AEInformatica/AE-Blanco.png" className="responsive-img" alt="" />        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /************/
}