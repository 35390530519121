/*****************************************
Funciones para creacion de input en formularios
Edwin Espinal
201908
*****************************************/


//***************************Librerias estandar de React y CSS principal
import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";
import './Input.css';

//*************************** Input General
function Input(props){
    let size=props.size || 's12';
    let clase=props.clase || '';
    let respuesta=
        <div className={"input-field col "+size+" "+clase}>
            {(props.icon)? <i className="material-icons prefix">{props.icon}</i>:''}
            <input 
                id={props.name}
                name={props.name} 
                type={props.type}
                className={"validate"}
                value={props.value || ''}
                onChange={props.action}
                onBlur={props.validation}
            />
            <label htmlFor={props.name}>{(props.label)?props.label:props.name}</label>
        </div>;
    return respuesta;
}

//*************************** Select estatico size clase name label value action
class StaticSelect extends React.Component{ 
    componentDidMount(){
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems,{});
    }
    render(){
        let size=this.props.size || 's12';
        let clase=this.props.clase || '';
        let option =this.props.options.map( option => {
            return (<option key={option.value} value={option.value}>{option.label}</option>);
        });
        let respuesta =
            <div className={"input-field col "+size+" "+clase}>
                {(this.props.icon)? <i className="material-icons prefix">{this.props.icon}</i>:''}
                <select 
                    name={this.props.name} 
                    id={this.props.name} 
                    value={this.props.value || ''} 
                    onChange={this.props.action}
                    onBlur={this.props.validation}
                >
                    <option key={'a'} value=''>Elije una opción</option>
                    {option}
                </select>
                <label>{(this.props.label)?this.props.label:this.props.name}</label>
            </div>
        return respuesta;
    }
}

//*************************** Input que autocompleta 3 caracteres, cargado externo
class Autocomplete extends React.Component{ 
    componentDidMount(){
        var option={
            data: this.props.datos,
            minLength: 3
        }
        var elems = document.querySelectorAll('.autocomplete');
        M.Autocomplete.init(elems,option);
    }
    render(){
        let size=this.props.size || 's12';
        let clase=this.props.clase || '';
        let respuesta =
            <div className={"input-field col "+size+" "+clase}>
                {(this.props.icon)? <i className="material-icons prefix">{this.props.icon}</i>:''}
                <input 
                    id="autocomplete-input"
                    name={this.props.name} 
                    type="text"  
                    className="autocomplete"
                    value={this.props.value || ''}
                    onChange={this.props.action}
                    onClick={this.props.action}
                    onBlur={this.props.validation}
                />
                <label htmlFor={this.props.name}>{(this.props.label)?this.props.label:this.props.name}</label>
            </div>
        return respuesta;
    }
}

//*************************** Botones, submit, reset  y boton
function Button(props){
    let size=props.size || 's10 offset-s1 ';
    let clase=props.clase || '';
    let respuesta=
        <div className={"boton col "+size+" "+clase}>
            <button 
                className={"btn waves-effect"} 
                type={props.type}
                name={props.name}
                id={props.name} 
                onClick={props.action}
            >
                {props.label}
                {(props.icon)?<i className="material-icons right ">{props.icon}</i>:''}
            </button>
        </div>
    return respuesta;
}

//***************************Exportar el componente
export {Input,StaticSelect,Autocomplete,Button}



/****************************Llamados a las funciones

<Input
    type="tipo"    
    name="Nombre"
    label="texto que aparece"
    icon="Material Icon"
    size="tamaño en columnas de materialize"
    clase="clase"
    validation="validacion al perder el foco"
    value="Valor por defecto"
    action="metodo de cambio"
/>

<StaticSelect
    name="nombre del componente"
    label="texto que aparece"
    icon="Material Icon"
    size="tamaño en columnas de materialize"
    clase="clase"
    validation="validacion al perder el foco"
    value="Valor por defecto"
    action="metodo de cambio"
    options=[
        {label:'nombre que aparece',value:'valor que tiene'},
        {label:'nombre que aparece',value:'valor que tiene'},
    ]
/>

<Autocomplete
    name="Nombre"
    label="texto que aparece"
    icon="Material Icon"
    size="tamaño en columnas de materialize"
    clase="clase"
    validation="validacion al perder el foco"
    value="Valor por defecto"
    action="metodo de cambio"
    datos={"dato1":null,"dato2":null,"dato3":null}
/>

<Button
    type="tipo"
    name="nombre del componente"
    label="texto que aparece"
    icon="Material Icon"
    size="tamaño en columnas de materialize"
    clase="clase"
    action="funcion a ejecutar con el click"
/>
*/