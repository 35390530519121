/*****************************************
Componente Aplicacion de citas GS1
Edwin Espinal
201908
*****************************************/

//***************************Librerias estandar de React y CSS principal
import React from 'react';
import ReactDOM from 'react-dom';
import 'materialize-css/dist/css/materialize.min.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
//***************************Librerias adicionales
import Inversion from './Componentes/Inversion.js';
import Panel from './Componentes/RegistroEmpresa.js';
import Evento from './Componentes/evento.js';

class MainApp extends React.Component{
    //***********************Constructor de clase
    constructor(props){
        super(props);
        this.state={
            Sesion:0,
            IdUsuario:'',
            Usuario:'',
            Inversion:''
        }
    }
    //***********************Dibujado de los componentes
    render(){
        return(
            <Router>
                <Switch>
                    <Route  path="/" exact render={
                        (props)=><Inversion {...props}  Accion={{Login:this.LoginUser, Inversion:this.SelectInversion}}  Datos={this.state} />
                    }/> 
                    <Route  path="/registroManana" exact render={
                        (props)=><Panel {...props}  Accion={{Login:this.LoginUser, Inversion:this.SelectInversion}}  Datos={this.state} />
                    }/> 
                    <Route  path="/registroTarde" exact render={
                        (props)=><Evento {...props}  Accion={{Login:this.LoginUser, Inversion:this.SelectInversion}}  Datos={this.state} />
                    }/> 
                    <Route  path="/registroDia" exact render={
                        (props)=><Panel {...props}  Accion={{Login:this.LoginUser, Inversion:this.SelectInversion}}  Datos={this.state} /> 
                    }/> 

                </Switch>
            </Router>
        );

    }
    //***********************Funciones adicionales
    LoginUser=(data) =>{
        this.setState({
            Sesion:data.Sesion,
            Usuario:data.Usuario,
            IdUsuario:data.IdUsuario,
        });
    }
    SelectInversion=(Inversion)=>{
        this.setState({
            Inversion:Inversion
        });
    }

    //***********************//
}
//***********************Componentes controlados



ReactDOM.render(<MainApp />, document.getElementById('root'));
serviceWorker.unregister();
