/*****************************************
Libreria para creación de formularios Akri
Edwin Espinal
201908
*****************************************/

//***************************Librerias estandar de React y CSS principal
import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './Form.css';

//***************************Librerias adicionales
import {Input,StaticSelect,Autocomplete,Button} from './Input.js';


//***************************Formulario de login del usuario 
class Form extends React.Component{  
    //constructor
    constructor(props){
        super(props);
        this.state=this.dataForm();
        this.handleChange=this.handleChange.bind(this);
        this.resetForm=this.resetForm.bind(this);
        this.submitForm=this.submitForm.bind(this);
    }

    //Construccion del formulario 
    render(){
        let size=this.props.param.size || 's12';
        let clase=this.props.param.clase || '';
        let campos=this.getFielt();
        let botones=this.getButton();
        return(
            <form className={"col "+size+' '+clase} onSubmit={this.submitForm}>
                <div className="frm_Encab">
                    {(this.props.param.icon)? <i className="material-icons">{this.props.param.icon}</i>:''}
                    {this.props.param.titulo}
                </div>
                <div className="frm_campos">
                   {campos}
                </div>
                <div className="frm_pie">
                    {botones}
                </div>
            </form>
        );
    }

    //Cargado inicial de variables
    dataForm(){
        let item;
        let obj={};
        for(var i in this.props.param.campos){
            item=this.props.param.campos[i];
            obj[item.name]=item.value;
        }
        return obj;
    }

    //Manejador de cambios en los campos
    handleChange(e){
        const value = e.target.value;
        this.setState({[e.target.name]: value});
    }

    //Procesar formulario
    submitForm(e){
        e.preventDefault();
        if(this.props.param.accion)
            this.props.param.accion(this.state,e);
    }

    //Reset del formulario
    resetForm(e){
        e.preventDefault();
        this.setState(this.dataForm());
    }

    //cargado de los botones del formulario
    getButton(){
        let resultado=[];
        let item;
        let fila;
        for(var i in this.props.param.boton){
            item=this.props.param.boton[i];
            switch(item.type){
                case'boton':
                    fila=
                    <Button
                        key={i.toString()}
                        type="button"
                        name={item.name}
                        label={item.label}
                        icon={item.icon}
                        size={item.size}
                        clase={item.clase}
                        action={item.action}
                    />
                break;
                case'reset':
                    fila=
                    <Button
                        key={i.toString()}
                        type="button"
                        name={item.name}
                        label={item.label}
                        icon={item.icon}
                        size={item.size}
                        clase={item.clase}
                        action={this.resetForm}
                    />
                break;
                default:
                    fila=
                    <Button
                        key={i.toString()}
                        type="submit"
                        name={item.name}
                        label={item.label}
                        icon={item.icon}
                        size={item.size}
                        clase={item.clase}
                        action={item.action}
                    />
                break;
            }
            resultado.push(fila);
        }
        return resultado;
    }

    //Cargado de los campos del formulario
    getFielt(){
        let resultado=[];
        let item;
        let fila;
        for(var i in this.props.param.campos){
            item=this.props.param.campos[i];
            switch(item.type){
                case 'StaticSelect': 
                    fila=
                        <StaticSelect
                            key={i.toString()}
                            name={item.name}
                            label={item.label}
                            icon={item.icon}
                            size={item.size}
                            clase={item.clase}
                            validation={item.validation}
                            value={this.state[item.name]}
                            action={this.handleChange}
                            options={item.options}
                        />
                break;
                case 'Autocomplete':
                    fila=
                        <Autocomplete
                            key={i.toString()}
                            name={item.name}
                            label={item.label}
                            icon={item.icon}
                            size={item.size}
                            clase={item.clase}
                            validation={item.validation}
                            value={this.state[item.name]}
                            action={this.handleChange}
                            datos={item.datos}
                        />
                break;
                default:
                    fila=
                        <Input
                            key={i.toString()}
                            name={item.name}
                            label={item.label}
                            type={item.type}
                            icon={item.icon}
                            clase={item.clase}
                            size={item.size}
                            value={this.state[item.name]}
                            action={this.handleChange}
                            validation={item.validation}
                        />
                break;
            }
            resultado.push(fila);
        }
        return resultado;
    }

    //***********************//
}


//***************************Exportar el componente
export default Form;


/*Llamados a las funciones

let formulario=
{
    titulo:'Nombre del formulario',
    icon: 'material icon, para el titulo',
    size:'s10 offset-s1 --- clases de tamaño de materialize',
    clase:'clase adicional',
    accion:{this.accionpara enviar el formulario}
    boton:[
        {
            type:"boton, submit, reset",
            name:"nombre del boton",
            label:"texto a mostrar en el boton",
            icon:"icono a mostrar dentro del boton",
            size:"tamaño en materialize",
            clase:"clase adicional para el boton",
            action:"funcion a ejecutar en onclick"
        }
    ],
    campos:[
        {
            type:"tipo generico por defecto (texto, mail, password..)",
            name:"Nombre",
            label:"texto que aparece",
            icon:"Material Icon",
            clase:"clase",
            size:"tamaño en columnas de materialize",
            value:"Valor por defecto",
            validation:"validacion al perder el foco"
        },
        {
            type:"StaticSelect",
            name:"nombre del campo",
            label:"Texto que se muestra",
            icon:"Material Icon",
            clase="clase",
            size="tamaño en columnas de materialize",
            value="Valor por defecto",
            options=[
                {label:'nombre que aparece',value:'valor que tiene'},
                {label:'nombre que aparece',value:'valor que tiene'},
            ]
        },
        {
            type:"Autocomplete",
            name:"test32",
            label:"texto test adicional",
            icon:"cloud",
            clase:"",
            value:"",
            datos:{"Daniel Ascencio":null,"Jorge Sosa":null,"Alejandro Duran":null}
        },
        
    ]
}
        
<Form param={formulario} />

*/