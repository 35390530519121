/*****************************************
Componente de inversion
Edwin Espinal
201908
*****************************************/

//***************************Librerias estandar de React y CSS principal
import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './Inversion.css';
import { Redirect } from 'react-router-dom'

//***************************Librerias adicionales

//***************************Formulario de login del usuario 
class Inversion extends React.Component{  
    //***********************Constructor de clase
    constructor(props){
        super(props);
        this.state=this.props.Datos
    }
    //***********************Dibujado de componetes 
    render(){
        let respuesta;
        switch (this.state.redirect){
            case 1:
                respuesta= <Redirect to='/registroManana' />
            break;
            case 2:
                respuesta= <Redirect to='/registroTarde' />
            break;
            case 3:
                respuesta= <Redirect to='/registroDia' />
            break;
            default:
                respuesta=<SelecInversion selectInversion={this.selectInversion} />
        }
        
        if(this.state.redirect===10){
            respuesta= <Redirect to='/registro' />
        }
        return respuesta;
    }
    //***********************Funciones adicionales
    selectInversion=(nombre,e)=>{
        e.preventDefault();
        this.props.Accion.Inversion(nombre);
        switch (nombre){
            case 'HagamosNegocio':
                this.setState({
                    redirect:1
                });
            break;
            case 'TallerFacebook':
                this.setState({
                    redirect:2
                });
            break;
            case 'TodoElDia':
                this.setState({
                    redirect:3
                });
            break;
            default:
        }
    }
    /******************************************/
}

//***********************Componenes controlados
function SelecInversion(props){
    return (
    <div className="InversionFondo">
        <div className="InversionContenido">
            <h4>Gracias por su interés.</h4>
            <h5>Seleccione su inversión:</h5>

            <div className="IversionGroupItem">

                <a href="#!" className="hoverable InversionItem" name="HagamosNegocio" onClick={(e)=>{props.selectInversion('HagamosNegocio',e)}}>
                    <p className="InversionTitle">Hagamos Negocio</p>
                    <div className="InversionPrice">
                        <p>Socios GS1:<span>$20</span></p>
                        <p>No Socio:<span>$40</span></p>
                    </div>
                    
                    <p className="InversionImportant">Incluye:</p>
                    <ul>
                        <li>Derecho a 2 personas</li>
                        <li>Coffe Break</li>
                        <li>Charlas</li>
                        <li>presentar 2 productos</li>
                        <li>2 citas con cadenas</li>
                    </ul>
                    <p className="InversionImportant">Precios no incluyen IVA.</p>
                    <p className="InversionFooter">Inversión por Empresa</p>
                </a>

                <a href="#!" className="hoverable InversionItem" name="TallerFacebook" onClick={(e)=>{props.selectInversion('TallerFacebook',e)}}>
                    <p className="InversionTitle">Taller Impulsa con Facebook</p>
                    <div className="InversionPrice">
                        <p>Socios GS1:<span>$30</span></p>
                        <p>No Socio:<span>$50</span></p>
                    </div>
                    <p className="InversionImportant">Incluye:</p>
                    <ul>
                        <li>Asistencia a Taller</li>
                        <li>Refrigerios</li>
                    </ul>
                    <p className="InversionImportant">Precios no incluyen IVA.</p>
                    <p className="InversionFooter">Inversión por Persona</p>
                </a>

                <a href="#!" className="hoverable InversionItem" name="TodoElDia" onClick={(e)=>{props.selectInversion('TodoElDia',e)}}>
                    <p className="InversionTitle">Asistir todo el día</p>
                    <div className="InversionPrice">
                        <p>Socios GS1:<span>$40</span></p>
                        <p>No Socio:<span>$60</span></p>
                    </div>
                    <p className="InversionImportant">Incluye:</p>
                    <ul>
                        <li>Derecho a 2 personas</li>
                        <li>Asistencia a Conferencias</li>
                        <li>Citas con cadenas</li>
                        <li>Taller de Facebook</li>
                        <li>Refrigerios</li>
                    </ul>
                    <p className="InversionImportant">Precios no incluyen IVA.</p>
                    <p className="InversionFooter">Inversión por Empresa</p>
                </a>

            </div>

        </div>
    </div>
    )
}
//***********************Exportar componentes
export default Inversion;